class ShopifyDistances {
 
    constructor({
      id,
      _id,
      municipality,
      distance,
    }) {
      this.id = id || _id;
      this.municipality = municipality;
      this.distance = distance;
    }
  
  }
  
  export default ShopifyDistances;