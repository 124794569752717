import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, message, Button, Modal, DatePicker, Input } from "antd";
import InterfaceTable from "../../Table/InterfaceTable";
import {
  getNftsSelector,
  getNftsCountSelector,
} from "../Infrastructure/Nfts.reducer";
import { getNfts } from "../Application/Nfts.business";
import CarbonFluxTable from "./CarbonFluxTable";

const { RangePicker } = DatePicker;
const { Search } = Input;

const NftsBoard = () => {
  const nftsData = useSelector(getNftsSelector);
  const totalCount = useSelector(getNftsCountSelector);

  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });

  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [carbonFluxData, setCarbonFluxData] = useState(null);
  const [selectedNftId, setSelectedNftId] = useState(null);

  const onChange = (changed_values) => {
    const value = changed_values.target
      ? changed_values.target.value
      : changed_values;
    fetchData({ q: value });
  };

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);

      const filters = {
        start_date: dateRange[0]
          ? dateRange[0].format("YYYY-MM-DD")
          : undefined,
        finish_date: dateRange[1]
          ? dateRange[1].format("YYYY-MM-DD")
          : undefined,
        type: typeFilter.length > 0 ? typeFilter : undefined,
        ...params,
      };

      Object.keys(filters).forEach((key) => {
        if (!filters[key]) delete filters[key];
      });

      const requestParams = {
        filter: filters,
        page: values.page,
        limit: values.limit,
      };

      try {
        const response = await getNfts(requestParams);
        console.log("Backend response:", response);
        setLoading(false);
      } catch (error) {
        console.error("Error in fetchData:", error);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in fetchData:", error);
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, filters = {}) => {
    const { current: page = 1, pageSize: limit = values.limit } = newPagination;

    const type = filters.type || [];

    setTypeFilter(type);

    setValues({
      ...values,
      page: page - 1,
      limit,
    });

    fetchData({ page: page - 1, limit });
  };

  const handleModalOpen = (record) => {
    setSelectedNftId(record.id);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates || []);
  };

  useEffect(() => {
    fetchData({ page: values.page, limit: values.limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, typeFilter, values.page, values.limit]);

  const columns = [
    { title: "Code", dataIndex: "assetCode", key: "assetCode" },
    {
      title: "Hash de Blockchain",
      dataIndex: "blockchainHash",
      key: "blockchainHash",
    },
    { title: "Hash de IPFS", dataIndex: "ipfsHash", key: "ipfsHash" },
    {
      title: "NFT/SBT",
      dataIndex: "type",
      key: "type",
      filters: [
        { text: "NFT", value: "is_nft" },
        { text: "SBT", value: "is_sbt" },
      ],
      render: (type) => (type === "is_sbt" ? "SBT" : "NFT"),
      filteredValue: typeFilter,
    },
    { title: "Proyecto", dataIndex: "projectName", key: "projectName" },
    { title: "Usuario", dataIndex: "username", key: "username" },
    {
      title: "Fecha de compra",
      dataIndex: "boughtAt",
      key: "boughtAt",
      render: (boughtAt) => (boughtAt ? boughtAt.slice(0, 10) : "N/A"),
    },
    { title: "Fecha de Offset", dataIndex: "usedAt", key: "usedAt" },
    { title: "Usado por", dataIndex: "usedBy", key: "usedBy" },
    {
      title: "Periodo de Offset",
      dataIndex: "offsetPeriod",
      key: "offsetPeriod",
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) => (
        <div>
          <Button onClick={() => handleModalOpen(record)}>
            Ver CarbonFlux
          </Button>
          {record.isSbt === "SBT" && (
            <Button
              href={record.certificate}
              target="_blank"
              style={{ marginLeft: 8 }}
            >
              Ver Certificado
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="grid-script" style={{ display: "grid" }}>
      <Row style={{ marginBottom: 20 }}>
        <Col xl={6}>
          <RangePicker
            onChange={handleDateChange}
            placeholder={["Fecha de inicio", "Fecha de fin"]}
          />
        </Col>
        <Col xl={12} style={{ width: "100%" }}>
          <Search
            placeholder="Buscar"
            onSearch={onChange}
            onChange={onChange}
            width="100%"
            className="search-item"
          />
        </Col>
      </Row>

      <InterfaceTable
        rowKey="id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          onChange: (current) => {
            setValues({
              ...values,
              page: current - 1,
            });
            fetchData({
              page: current - 1,
              limit: values.limit,
            });
          },
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            setValues({
              ...values,
              limit: size,
              page: current - 1,
            });
            fetchData({
              page: current - 1,
              limit: size,
            });
          },
        }}
        data={nftsData}
        columns={columns}
        loading={loading}
      />
      <Modal
        title="Información de CarbonFluxes"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="95%"
      >
        <CarbonFluxTable id={selectedNftId} />
      </Modal>
    </div>
  );
};

export default NftsBoard;
