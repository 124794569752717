import Response from "../../Data/Domain/Response";
import Logs from "../Domain/Logs";
import { getLogs,getLog } from "./Logs.service";

export async function getLogsRepository(params) {
  const data = await getLogs(params);
  return new Response(data, Logs).getMultiple();
}
export async function getLogRepository(id) {
  const data = await getLog(id);
  return new Response(data, Logs).getSingle();
}