import Formatter from "../../Data/Domain/Formatter";

class Nft {
  constructor({
    _id,
    asset_code,
    blockchain_hash,
    ipfs_hash,
    is_sbt,
    project_name,
    username,
    bought_at,
    used_at,
    used_by,
    offset_period,
    certificate,
  }) {
    this.id = _id;
    this.assetCode = asset_code;
    this.blockchainHash = blockchain_hash;
    this.ipfsHash = ipfs_hash;
    this.isSbt = is_sbt ? "SBT" : "NFT";
    this.projectName = project_name;
    this.username = username;
    this.boughtAt = bought_at;
    this.usedAt = used_at;
    this.usedBy = used_by;
    this.offsetPeriod = offset_period;
    this.certificate = certificate;
  }

  static fromJSON(data) {
    return new Nft(data);
  }
}

export default Nft;
