import { getLogsRepository } from "../Infrastructure/Logs.repository";
import { saveListLogs, saveCountLogs, saveSelectedLog } from "../Infrastructure/Logs.store";
import { getLogLogic } from "./Logs.logic";

export async function getLogs(params) {
  const { count, resources: logs } = await getLogsRepository(params);
  saveListLogs(logs);
  saveCountLogs(count);
}
export async function getLog(id) {
  const log = await getLogLogic(id);
  saveSelectedLog(log);
}