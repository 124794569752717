import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Row, message } from "antd";
import { Title } from "../../Utils/UI/Text";
import Formatter from "../../Data/Domain/Formatter";
import moment from "moment";
import InterfaceTable from "../../Table/InterfaceTable";
import Statistics from "../../Statistics/Statistics";
import { getCommissionsSelector } from "../Infrastructure/Commissions.reducer";
import { getCommissions } from "../Application/Commissions.bussines";
import RangePicker from "../../Layout/DesignSystem/Picker/Picker";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";

const Commissions = () => {
  const commissions = useSelector(getCommissionsSelector);
  useEffect(() => {
    getCommissions();
  }, []);

  const dates = useRef([]);
  const [values, setValues] = useState({
    page: 0,
    limit: 10,
  });
  const [filters, setFilters] = useState("false");


  const onChange = (changed_values) => {
    dates.current = !!changed_values
      ? changed_values.map((value) => {
        return moment(value).format("YYYY-MM-DD");
      })
      : [];
    handleRequest({}, {}, {});
  };

  const fetchData = async (params = {}) => {
    try {
      await getCommissions(params);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      message.success("Filtrado");
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 10 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: dates.current,
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  const CommissionsColumns = [
    {
      title: "Tipo",
      dataIndex: "typeValue",
      key: "typeValue",
      filters: [
        {
          text: "Ofertas",
          value: `offers`,
        },
        {
          text: "Bonos de Carbono",
          value: `bonds`,
        },
      ],
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Comisión USD",
      dataIndex: "calculateFee",
      key: "calculateFee",
    },
  ];
  const datePicker = {
    marginBottom: 20,
    marginTop: 20,
  };
  const title = {
    marginBottom: 20,
  };
  const statistics = [
    {
      value: `$${Formatter.formatUsd(commissions.calculateTotal)}`,
      title: "Comisiones Totales",
    },
    {
      value: `$${Formatter.formatUsd(commissions.calculateBonds)}`,
      title: "Comisiones de bonos de carbono",
    },
    {
      value: `$${Formatter.formatUsd(commissions.calculateOffer)}`,
      title: "Comisiones de Ofertas",
    },
    {
      value: `$${Formatter.formatUsd(commissions.calculateOthers)}`,
      title: "Otras comisiones",
    },
    
  ];

  return (
    <>

      <div
        style={{
          display: "flex",
          justifyContent: 'space-between',
          marginBottom: 30,
        }}
      >
        <div>
          <Title>Comisiones</Title>
        </div>
        <div>
          <ExportButtonElement
            type={"fees"}
            text={"Exportar"}
            filter={filters}
          />
        </div>
      </div>
      <Statistics statistics={statistics} />

      <Row style={datePicker}>
        <RangePicker onChange={onChange} />
      </Row>

      <InterfaceTable
        rowKey="id"
        data={commissions.resources}
        columns={CommissionsColumns}
        onChange={handleRequest}
        pagination={{
          current: values.page + 1,
          pageSize: values.limit,
          total: commissions.count,
        }}
      />
    </>
  );
};

export default Commissions;
