import { saveListShopifyDistances, saveCountShopifyDistances } from "../Infrastructure/ShopifyDistances.store";
import { getShopifyDistancesLogic } from "./ShopifyDistances.logic";

export async function getShopifyDistances(params) {
  const { count, resources: shopifyDistances } = await getShopifyDistancesLogic(params);
  saveListShopifyDistances(shopifyDistances);
  saveCountShopifyDistances(count);
}


