import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, DatePicker, Row, Col, message, Button } from "antd";
import moment from "moment";
import { getStationData } from "../Application/StationData.business";
import {
  getStationDataSelector,
  getStationDataCountSelector,
} from "../Infrastructure/StationData.reducer";

const { RangePicker } = DatePicker;

export default function StationDataBoard() {
  const stationData = useSelector(getStationDataSelector);
  const totalCount = useSelector(getStationDataCountSelector);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [qualityFilter, setQualityFilter] = useState(null);
  const [uploadedFilter, setUploadedFilter] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [shouldFetch, setShouldFetch] = useState(false);

  const fetchData = async (params = {}) => {
    setLoading(true);
    try {
      const filter = {
        start_date: dateRange[0]
          ? dateRange[0].format("YYYY-MM-DD")
          : undefined,
        finish_date: dateRange[1]
          ? dateRange[1].format("YYYY-MM-DD")
          : undefined,
        qc_co2_flux:
          qualityFilter !== null ? [qualityFilter.toString()] : undefined,
        is_uploaded: uploadedFilter !== null ? [uploadedFilter] : undefined,
      };

      Object.keys(filter).forEach(
        (key) => filter[key] === undefined && delete filter[key]
      );

      const requestParams = {
        filter,
        page: pagination.current - 1,
        limit: pagination.pageSize,
        ...params,
      };

      console.log("Fetching station data with params:", requestParams);
      await getStationData(requestParams);
      setLoading(false);
    } catch (error) {
      message.error("Error fetching station data");
      setLoading(false);
    }
  };

  const handleTableChange = (newPagination, filters) => {
    setPagination({
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });

    const quality = filters.qcCo2Flux ? filters.qcCo2Flux[0] : null;
    setQualityFilter(quality);

    const uploaded = filters.uploaded ? filters.uploaded[0] : null;
    setUploadedFilter(uploaded);

    setShouldFetch(true); // Marca que los filtros están listos para ejecutar fetchData
  };

  const formatNumber = (value) =>
    value ? parseFloat(value).toFixed(4) : "0.0000";

  const formattedData = stationData.map((item) => ({
    ...item,
    co2FluxGM2: formatNumber(item.co2FluxGM2),
    co2Flux: formatNumber(item.co2Flux),
    co2FluxHa: formatNumber(item.co2FluxHa),
  }));

  const columns = [
    { title: "ID del cliente", dataIndex: "id", key: "id" },
    { title: "CO2 Flux (g/m²)", dataIndex: "co2FluxGM2", key: "co2FluxGM2" },
    { title: "CO2 Flux", dataIndex: "co2Flux", key: "co2Flux" },
    { title: "CO2 Flux (g/ha)", dataIndex: "co2FluxHa", key: "co2FluxHa" },
    {
      title: "Fecha (DD-MM-AAAA)",
      dataIndex: "datetime",
      key: "datetime",
      render: (datetime) => moment(datetime).format("YYYY-MM-DD"),
    },
    {
      title: "Calidad de la data (0 a 2)",
      dataIndex: "qcCo2Flux",
      key: "qcCo2Flux",
      filters: [
        { text: "0", value: 0 },
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
      ],
    },
    {
      title: "Archivos cargados (IPFS/Blockchain)",
      dataIndex: "uploaded",
      key: "uploaded",
      filters: [
        { text: "Sí", value: true },
        { text: "No", value: false },
      ],
      render: (uploaded) => (uploaded ? "Sí" : "No"),
    },
    { title: "Estación", dataIndex: "station", key: "station" },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) =>
        record.uploaded ? (
          <Button
            onClick={() =>
              console.log("URLs:", {
                ipfs_raw_data_url: record.ipfs_raw_data_url,
                ipfs_report_url: record.ipfs_report_url,
                blockchain_raw_data_url: record.blockchain_raw_data_url,
                blockchain_report_url: record.blockchain_report_url,
              })
            }
          >
            Ver URLs
          </Button>
        ) : null,
    },
  ];

  useEffect(() => {
    if (shouldFetch) {
      fetchData();
      setShouldFetch(false);
    }
  }, [shouldFetch]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Row style={{ marginBottom: 16 }} justify="space-between" align="middle">
        <Col>
          <RangePicker
            onChange={(dates) => setDateRange(dates)}
            placeholder={["Fecha de inicio", "Fecha de fin"]}
          />
        </Col>
      </Row>
      <Table
        dataSource={formattedData}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}