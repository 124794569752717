import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Col, Row, message } from "antd";
import { Title } from "../../Utils/UI/Text";
import "antd/dist/antd.min.css";
import moment from "moment";
import {
  getSelectedTransactionSelector,
  getTransactionsSelector,
  getTransactionsCountSelector,
  getTotalTransactionsSelector,
  getTotalUsdSelector,
} from "../Infrastructure/Transactions.reducer";
import {
  getTransactions,
  getTransaction,
} from "../Application/Transactions.business";
import TransactionDetail from "./TransactionDetail";
import Formatter from "../../Data/Domain/Formatter";
import Statistics from "../../Statistics/Statistics";
import InterfaceTable from "../../Table/InterfaceTable";
import RangePicker from "../../Layout/DesignSystem/Picker/Picker";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";

const columns = [
  {
    title: "Tipo",
    dataIndex: "typeValue",
    key: "typeValue",
    filters: [
      {
        text: "Compra de dólares",
        value: "buy_usd",
      },
      {
        text: "Compra de bonos",
        value: "buy_bonds",
      },
      {
        text: "Cálculo de huella",
        value: "footprint_offset",
      },
      {
        text: "Compra de Crédito de Carbono (NFT)",
        value: "buy_nft",
      },
      {
        text: "Offset de Crédito de Carbono (SBT)",
        value: "buy_sbt",
      },
    ],
  },
  {
    title: "Estado",
    dataIndex: "stateValue",
    key: "stateValue",
    filters: [
      {
        text: "Pendiente",
        value: "pending",
      },
      {
        text: "Finalizado",
        value: "accepted",
      },
    ],
  },
  {
    title: "Fecha",
    dataIndex: "date",
    key: "date",
    sorter: true,
  },
  {
    title: "Monto",
    dataIndex: "calculatePayment",
    key: "calculatePayment",
    render: (value) => Formatter.formatUsd(parseFloat(value)),
  },
];



export default function TransactionsBoard({ data }) {
  const record = useSelector(getSelectedTransactionSelector);
  const transactions = useSelector(getTransactionsSelector);
  const count_transactions = useSelector(getTransactionsCountSelector);
  const total_transactions = useSelector(getTotalTransactionsSelector);
  const total_usd = useSelector(getTotalUsdSelector);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dates = useRef([]);
  const [values, setValues] = useState({
    page: 0,
    limit: 10,
  });
  const [filters, setFilters] = useState("false");

  const onChange = (changed_values) => {
    dates.current = !!changed_values
      ? changed_values.map((value) => {
          return moment(value).format("YYYY-MM-DD");
        })
      : [];
    handleRequest({}, {}, {});
  };

  const fetchData = async (params = {}) => {
    try {
      await getTransactions(params);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 10 } = newPagination;
    const { field: field_sort, order: sortOrder } = sorter;
    const sort = sortOrder === "ascend" ? "ascending" : "descending";

    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      field_sort,
      sort,
      page: page - 1,
      dates: dates.current,
    });

    setValues({
      ...values,
      limit,
      page: page - 1,
    });

    fetchData(clean);
  };

  const statistics = [
    {
      value: Formatter.formatUsd(total_usd),
      title: "Total de Transacciones",
      unit: "USD",
    },
    {
      value: total_transactions,
      title: "Número Total de Transacciones",
    },
  ];

  const datePicker = {
    marginBottom: 20,
    marginTop: 20,
  };

  return (
    <main>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div>
          <Title>Transacciones</Title>
        </div>
        <div>
          <ExportButtonElement
            type={"transactions"}
            text={"Exportar"}
            filter={filters}
          />
        </div>
      </div>

      <Statistics statistics={statistics} />

      <Row style={datePicker}>
        <RangePicker onChange={onChange} />
      </Row>

      <Row>
        <Col span={24}>
          <InterfaceTable
            rowKey="id"
            onRow={(selected) => {
              return {
                onClick: () => {
                  getTransaction(selected.id);
                  setVisible(true);
                },
              };
            }}
            data={transactions}
            columns={columns}
            pagination={{
              current: values.page + 1,
              pageSize: values.limit,
              total: count_transactions,
            }}
            loading={loading}
            onChange={handleRequest}
          />
        </Col>
      </Row>

      {record && (
        <TransactionDetail
          onCancel={() => setVisible(false)}
          record={record}
          visible={visible}
          onClose={() => setVisible(false)}
        />
      )}
    </main>
  );
}
