import { createSlice } from "@reduxjs/toolkit";
import { getLogs } from "../Application/Logs.business";

export const slice = createSlice({
  name: "logs",
  initialState: {
    list: [],
    count: 0,
    selected: null,
  },
  reducers: {
    setLogs: (state, { payload: list }) => ({
      ...state,
      list,
    }),
    countLogs: (state, { payload: count }) => ({
      ...state,
      count,
    }),
    selectLog: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
  },
});

export const { setLogs, countLogs,selectLog } = slice.actions;

export default slice.reducer;

export const getLogsSelector = (store) => store.logs?.list || [];
export const getLogsCountSelector = (store) => store.logs.count;

export const fetchLogs = (params) => async (dispatch) => {
  try {
    console.log("Fetching logs with params:", params);
    const response = await getLogs(params);
    dispatch(setLogs(response.data.resources));
    dispatch(countLogs(response.data.count));
  } catch (error) {
    console.error("Error fetching logs:", error);
  }
};
export const getSelectedLogSelector = (store) => store.logs.selected;
