class Logs {
 
  constructor({
    id,
    _id,
    datetime,
    data,
    description,
    type,
    uid
  }) {
    this.id = id || _id;
    this.datetime = datetime;
    this.data = data;
    this.description = description;
    this.type = type;
    this.uid=uid;
   
  }

}

export default Logs;
  