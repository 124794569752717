import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Title } from "../../Utils/UI/Text";
import ShopifyDistancesBoard from "./ShopifyDistancesBoard";
import { getShopifyDistances } from "../Application/ShopifyDistances.business";
import {
  getShopifyDistancesSelector,
  getShopifyDistancesCountSelector,
} from "../Infrastructure/ShopifyDistances.reducer";
import "./ShopifyDistances.css";
import { Card } from "antd";

const ShopifyDistances = () => {
  const shopifyDistances = useSelector(getShopifyDistancesSelector);
  const totalCounShopifyDistances = useSelector(
    getShopifyDistancesCountSelector
  );

  useEffect(() => {
    getShopifyDistances();
  }, []);
  return (
    <section className="shopify-distances-container">
      <Card className="shopify-distances-card" bordered={false}>
        <header className="shopify-distances-header">
          <Title>Distancias de Shopify</Title>
        </header>
        <main>
          <ShopifyDistancesBoard
            shopifyDistances={shopifyDistances}
            getShopifyDistances={getShopifyDistances}
            totalCounShopifyDistances={totalCounShopifyDistances}
          />
        </main>
      </Card>
    </section>
  );
};

export default ShopifyDistances;
