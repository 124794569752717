import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getNfts(params) {
  try {
    const url = URL.setParams(URL.buildUrl("nfts"), params);
    //console.log("Final Request URL:", url);
    const response = await new API(url).get();

    return response;
  } catch (error) {
    console.error("Error in getNfts:", error);
    throw error;
  }
}

export async function getCarbonFlux(id) {
  return await new API(URL.buildUrl(`nfts/${id}/carbonfluxes`)).get();
}
