import store from "../../Redux/store";
import { setLogs, countLogs, selectLog } from "./Logs.reducer";

export function saveListLogs(records) {
  store.dispatch(setLogs(records));
}

export function saveCountLogs(count) {
  store.dispatch(countLogs(count));
}
export function saveSelectedLog(log) {
  store.dispatch(selectLog(log));
}