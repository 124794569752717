import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import newLogo from "../assets/logos/newLogo.png";
import { Layout, Menu, Drawer, Button, Tooltip, Spin } from "antd";
import {
  SolutionOutlined,
  UserOutlined,
  BarChartOutlined,
  DotChartOutlined,
  MenuOutlined,
  ProjectOutlined,
  DashboardOutlined,
  ShopOutlined,
  StrikethroughOutlined,
  TeamOutlined,
  AppstoreOutlined,
  LogoutOutlined,
  DownloadOutlined,
  DesktopOutlined,
  ExclamationCircleOutlined,
  ContainerOutlined,
  DropboxOutlined,
  DatabaseOutlined,
  UserSwitchOutlined,
  AuditOutlined,
  UpOutlined,
  GlobalOutlined,
  PictureOutlined,
  FileAddOutlined,
  CloudServerOutlined,
  CreditCardOutlined,
  FireOutlined,
  LinkOutlined,
  HistoryOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import "./SiteLayout.css";
import "antd/dist/antd.min.css";
import Administrator from "../Administrator/UI/Administrator";
import Offers from "../Offers/UI/Offers";
import Transactions from "../Transactions/UI/Transactions";
import Users from "../Users/UI/Users";
import Dashboard from "../Dashboard/UI/Dashboard";
import CRM from "../CRM/UI/CRM";
import Projects from "../Projects/UI/Projects";
import Companies from "../Companies/UI/Companies";
import { role as getRoleFromAPI } from "../Login/Application/Auth.logic";
import ErrorPage from "../Error/UI/ErrorPage";
import Commissions from "../Commissions/UI/Commissions";
import Categories from "../Categories/UI/Categories";
import UserReferenceField from "../Users/UI/UserReferenceField";
import Logout from "../Login/UI/Logout";
import Scopes from "../Scopes/UI/Scopes";
import DashboardOffset from "../DashboardOffset/UI/DashboardOffset";
import Test from "../Test";
import Saving from "../Saving/UI/Saving";
import Reports from "../Reports/Reports";
import Offset from "../Offset/Offset";
import Collaborators from "../Collaborators/UI/Collaborators";
import RepositoryProjects from "../RepositoryProjects/UI/RepositoryProjects";
import Suppliers from "../Suppliers/UI/suppliers";
import Areas from "../Areas/UI/Areas";
import User from "../Users/Domain/User";
import UserMenu from "./UserMenu";
import ManageData from "../ManageData/UI/ManageData";
import FetchData from "../FetchData/UI/FetchData";
import Owners from "../Owners/UI/Owners";
import Storage from "../Data/Domain/Storage";
import Permissions from "../Permissions/UI/Permissions";
import IPFSPage from "../Owners/UI/IPFSPage";
import { useParams, useNavigate } from "react-router-dom";
import Generals from "../Owners/UI/Generals";
import Images from "../Owners/UI/Images";
import Files from "../Files/UI/Files";
import TransactionsProjects from "./../Owners/UI/TransactionsProjects";
import ScriptOffsets from "../ScriptOffsets/UI/ScriptOffsets";
import StationData from "../StationData/UI/StationData";
import CarbonFlux from "../CarbonFlux/UI/CarbonFlux";
import Nfts from "../NFTS/UI/Nfts";
import Logs from "../Logs/UI/Logs";
import ShopifyDistances from "../ShopifyDistances/UI/ShopifyDistances";

const id = new Storage("id").getItem();

const { Content, Sider } = Layout;

const styles = {
  menuItem: {
    backgroundColor: "transparent",
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  flex: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  menuOutlined: {
    backgroundColor: "#041370",
    color: "#FFFFFF",
  },
  link: {
    color: "inherit",
    textDecoration: "inherit",
  },

  containerTextSecond: {
    display: "flex",
    flexDirection: "column",
  },
};

const SiteLayout = ({ defaultPage = "users" }) => {
  const [role, setRole] = useState(null);
  const [page, setPage] = useState(defaultPage);
  const [visible, setVisible] = useState(false);
  const [filteredPages, setFilteredPages] = useState({});
  const [loading, setLoading] = useState(true);
  const [showSpin, setShowSpin] = useState(false);
  const roles = useSelector((state) => state.users.roles);
  const { recordId } = useParams();

  const pages = {
    dashboard: {
      title: "Dashboard",
      icon: <DashboardOutlined />,
      page: <Dashboard />,
      roles: [User.SUPERADMIN],
    },
    dashboardOffset: {
      title: "DashboardOffset",
      icon: <AppstoreOutlined />,
      page: <DashboardOffset />,
      roles: [User.SUPERADMIN, User.LEGAL, User.PARTNER],
    },
    offers: {
      title: "Ofertas",
      icon: <BarChartOutlined />,
      page: <Offers />,
      roles: [User.SUPERADMIN, User.ADMIN, User.CARBON_CREDITS],
    },
    transactions: {
      title: "Transacciones",
      icon: <DotChartOutlined />,
      page: <Transactions />,
      roles: [User.SUPERADMIN, User.ADMIN, User.CARBON_CREDITS],
    },
    users: {
      title: "Usuarios",
      icon: <UserOutlined />,
      page: <Users />,
      roles: [User.SUPERADMIN, User.ADMIN, User.CARBON_CREDITS],
    },
    projects: {
      title: "Proyectos",
      icon: <ProjectOutlined />,
      page: <Projects />,
      roles: [
        User.SUPERADMIN,
        User.ADMIN,
        User.PROJECTS_VIEWER,
        User.PROJECTS_SELLER,
        User.CARBON_CREDITS,
      ],
    },
    administrator: {
      title: "Administradores",
      icon: <SolutionOutlined />,
      page: <Administrator />,
      roles: [User.SUPERADMIN, User.ADMIN, User.CARBON_CREDITS],
    },
    companies: {
      title: "Compañías",
      icon: <ShopOutlined />,
      page: <Companies />,
      roles: [User.LEGAL, User.ADMIN, User.CARBON_CREDITS],
    },
    error: {
      page: <ErrorPage />,
      roles: [],
    },
    commissions: {
      title: "Comisiones",
      icon: <StrikethroughOutlined />,
      page: <Commissions />,
      roles: [User.SUPERADMIN, User.ADMIN, User.LEGAL, User.CARBON_CREDITS],
    },
    crm: {
      title: "CRM",
      icon: <TeamOutlined />,
      page: <CRM />,
      roles: [User.SUPERADMIN, User.ADMIN, User.LEGAL, User.CARBON_CREDITS],
    },
    categories: {
      title: "Categorías",
      icon: <AppstoreOutlined />,
      page: <Categories />,
      roles: [User.SUPERADMIN, User.ADMIN, User.LEGAL],
    },
    test: {
      title: "Test",
      icon: <AppstoreOutlined />,
      page: <Test />,
      roles: [User.SUPERADMIN, User.ADMIN],
    },
    scopes: {
      title: "Scopes",
      icon: <BarChartOutlined />,
      page: <Scopes />,
      roles: [User.SUPERADMIN, User.PARTNER],
    },
    saving: {
      title: "Reductions",
      icon: <DownloadOutlined />,
      page: <Saving />,
      roles: [User.SUPERADMIN, User.LEGAL, User.PARTNER],
    },
    reports: {
      title: "Reports",
      icon: <DesktopOutlined />,
      page: <Reports />,
      roles: [User.SUPERADMIN, User.LEGAL, User.PARTNER],
    },
    offset: {
      title: "Offset",
      icon: <ExclamationCircleOutlined />,
      page: <Offset />,
      roles: [User.SUPERADMIN, User.LEGAL, User.PARTNER],
    },
    manageData: {
      title: "ManageData",
      icon: <DatabaseOutlined />,
      page: <ManageData />,
      roles: [User.SUPERADMIN, User.CARBON_MANAGEMENT],
    },
    fetchData: {
      title: "FetchData",
      icon: <CloudServerOutlined />,
      page: <FetchData />,
      roles: [User.SUPERADMIN, User.CARBON_MANAGEMENT],
    },
    collaborators: {
      title: "Collaborators",
      icon: <TeamOutlined />,
      page: <Collaborators />,
      roles: [User.SUPERADMIN, User.LEGAL, User.PARTNER],
    },
    suppliers: {
      title: "Suppliers",
      icon: <DropboxOutlined />,
      page: <Suppliers />,
      roles: [User.SUPERADMIN, User.ADMIN, User.CARBON_MANAGEMENT],
    },
    areas: {
      title: "Areas",
      icon: <AppstoreOutlined />,
      page: <Areas />,
      roles: [User.SUPERADMIN, User.ADMIN, User.CARBON_MANAGEMENT],
    },
    repositoryProjects: {
      title: "RepositoryProjects",
      icon: <ContainerOutlined />,
      page: <RepositoryProjects />,
      roles: [User.SUPERADMIN, User.ADMIN, User.CARBON_MANAGEMENT],
    },
    owners: {
      title: "Propietario del proyecto",
      icon: <UserSwitchOutlined />,
      page: <Owners setPage={setPage} />,
      roles: [User.SUPERADMIN, User.ADMIN, User.PROJECT_OWNER, User.OWNER],
    },
    ipfs: {
      title: "Ipfs",
      icon: <UpOutlined />,
      page: <IPFSPage recordId={recordId} />,
      roles: [User.SUPERADMIN, User.ADMIN, User.PROJECT_OWNER, User.OWNER],
    },
    permissions: {
      title: "Permisos",
      icon: <AuditOutlined />,
      page: <Permissions />,
      roles: [User.ADMIN, User.OWNER],
    },
    generals: {
      title: "Generals",
      icon: <GlobalOutlined />,
      page: <Generals recordId={recordId} />,
      roles: [User.SUPERADMIN, User.ADMIN, User.PROJECT_OWNER, User.OWNER],
    },
    images: {
      title: "Images",
      icon: <PictureOutlined />,
      page: <Images recordId={recordId} />,
      roles: [User.SUPERADMIN, User.ADMIN, User.PROJECT_OWNER, User.OWNER],
    },
    files: {
      title: "Files",
      icon: <FileAddOutlined />,
      page: <Files />,
      roles: [
        User.SUPERADMIN,
        User.ADMIN,
        User.CARBON_MANAGEMENT,
        User.PARTNER,
      ],
    },
    transactionsProjects: {
      title: "Transacciones",
      icon: <GlobalOutlined />,
      page: <TransactionsProjects recordId={recordId} />,
      roles: [User.SUPERADMIN, User.ADMIN, User.PROJECT_OWNER, User.OWNER],
    },
    stationData: {
      title: "StationData",
      icon: <DatabaseOutlined />,
      page: <StationData />,
      roles: [User.ADMIN],
    },
    carbonflux: {
      title: "CarbonFlux",
      icon: <FireOutlined />,
      page: <CarbonFlux />,
      roles: [User.ADMIN],
    },
    nfts: {
      title: "Nfts",
      icon: <LinkOutlined />,
      page: <Nfts />,
      roles: [User.ADMIN],
    },
    logs: {
      title: "Logs",
      icon: <HistoryOutlined />,
      page: <Logs />,
      roles: [User.ADMIN],
    },
    scriptOffsets: {
      title: "ScriptOffsets",
      icon: <CreditCardOutlined />,
      page: <ScriptOffsets />,
      roles: [User.SUPERADMIN, User.ADMIN, User.CARBON_MANAGEMENT],
    },
    shopifyDistances: {
      title: "ShopifyDistances",
      icon: <EnvironmentOutlined />,
      page: <ShopifyDistances />,
      roles: [User.ADMIN, User.CARBON_MANAGEMENT],
    },
    logout: {
      title: "Logout",
      icon: <LogoutOutlined />,
      page: "",
      roles: [
        User.OWNER,
        User.SUPERADMIN,
        User.ADMIN,
        User.LEGAL,
        User.PARTNER,
        User.PROJECTS_VIEWER,
        User.PROJECTS_SELLER,
        User.CARBON_MANAGEMENT,
        User.CARBON_CREDITS,
      ],
    },
  };
  const handleClick = (event) => {
    const clickedPage = event.keyPath[0];
    const id = new Storage("id").getItem();
    console.log("clicked Page:", clickedPage);
    if (
      clickedPage === `owners/${id}/projects` ||
      clickedPage === `owners/${id}/permissions`
    ) {
      setPage(null);
      setFilteredPages((prevPages) => ({
        ...prevPages,
        owners: {
          ...pages.owners,
          page:
            clickedPage === `owners/${id}/projects` ? (
              <Owners setPage={setPage} />
            ) : (
              <Permissions />
            ),
        },
      }));
      return;
    }
    setPage(clickedPage);
  };

  const getRole = async () => {
    const role_object = await getRoleFromAPI();
    const { role: role_string } = role_object;
    const localRole = localStorage.getItem("rol");

    if (role_string.includes(localRole)) {
      setRole(localRole);
    }
    setLoading(false);
  };

  const filterByRole = () => {
    if (role) {
      const newDefaultPage = User.getPageBasedOnRole(role);
      setPage(newDefaultPage);

      const filtered = Object.keys(pages).reduce((acc, key) => {
        const element = pages[key];
        const { roles } = element;
        const can_see = roles.some((element) => role.includes(element));

        if (can_see) {
          acc[key] = element;
        }

        return acc;
      }, {});
      setFilteredPages(filtered);
    }
  };

  useEffect(() => {
    if (role) {
      getRole();
      filterByRole();
      setShowSpin(true);
      const timer = setTimeout(() => {
        setShowSpin(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  useEffect(() => {
    getRole();
    filterByRole();
  }, []);

  useEffect(() => {
    filterByRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const getDefaultPage = (roles) => {
    for (const key in pages) {
      const element = pages[key];
      const { roles: pageRoles } = element;
      if (pageRoles.some((role) => roles.includes(role))) {
        return key;
      }
    }
  };
  useEffect(() => {
    if (role) {
      const defaultPage = getDefaultPage(role);
      setPage(defaultPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const MenuResponsive = () => {
    return (
      <Drawer
        title="Topics"
        placement="left"
        onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <Menu
          theme={"light"}
          mode={"inline"}
          onClick={handleClick}
          defaultSelectedKeys={[pages[page]]}
        >
          {Object.keys(filteredPages).map((key) => {
            const element = filteredPages[key];
            const { icon, title } = element;
            if (key === "owners") key = `${key}/${id}/projects`;
            if (key === "permissions") key = `owners/${id}/permissions`;
            if (
              icon.type !== UpOutlined &&
              icon.type !== GlobalOutlined &&
              icon.type !== PictureOutlined
            ) {
              return (
                <Menu.Item key={key} icon={icon}>
                  <Link className={"link-dash"} to={`/${key}`}>
                    {title}
                  </Link>
                </Menu.Item>
              );
            } else {
              return null;
            }
          })}
        </Menu>
      </Drawer>
    );
  };

  const MenuSideBar = () => {
    return (
      <Menu
        // className="menu-sidebar"
        className={
          Object.keys(filteredPages).length > 10
            ? "menu-sidebar"
            : "menu-sidebar-fixed"
        }
        theme="light"
        mode="inline"
        onClick={pages.logout === <LogoutOutlined /> ? onclick : handleClick}
        defaultSelectedKeys={[page]}
      >
        {Object.keys(filteredPages).map((key) => {
          const element = filteredPages[key];
          const { icon, title } = element;
          if (key === "owners") key = `${key}/${id}/projects`;
          if (key === "permissions") key = `owners/${id}/permissions`;

          if (
            icon.type !== UpOutlined &&
            icon.type !== GlobalOutlined &&
            icon.type !== PictureOutlined
          ) {
            return (
              <Menu.Item style={styles.menuItem} key={key}>
                <div style={styles.flex}>
                  <Tooltip title={title}>
                    <Link style={styles.link} to={`/${key}`}>
                      {icon}
                    </Link>
                  </Tooltip>
                  <div className="icon-event">
                    <span className="event" />
                  </div>
                </div>
              </Menu.Item>
            );
          } else {
            return null;
          }
        })}
      </Menu>
    );
  };
  const { onclick } = Logout();

  return (
    <div>
      <Layout.Header className={"bar"}>
        <div>
          <div className={"menuOutlined-dash"}>
            <Button
              type="primary"
              icon={<MenuOutlined />}
              onClick={() => setVisible(true)}
              style={styles.menuOutlined}
            />
          </div>
          <MenuResponsive />
          <Link to={"/users"}>
            <img className={"logo-dash"} alt="logo" src={newLogo} />
          </Link>
        </div>
        <div>
          <UserReferenceField
            dashboard={true}
            occupation="Dev"
            stylesUsername={"username"}
            container={"container"}
            containerText={"containerText"}
            containerRight={"containerRight"}
            occupationStyle={"occupation"}
            size={49}
            dropdown={<UserMenu />}
          />
        </div>
      </Layout.Header>
      <Layout>
        <Sider className={"sidebar"} breakpoint={"lg"}>
          <MenuSideBar />
        </Sider>
        <Layout>
          <Content className={"content-pages-dash"}>
            <div className={"content-dash"}>
              {showSpin && (
                <div className="content-spin">
                  <Spin size="large" />
                </div>
              )}
              {!page && filteredPages.owners && filteredPages.owners.page}

              {page === "permissions" && !filteredPages.permissions && (
                <Permissions />
              )}

              {page && pages[page]?.page}
            </div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default SiteLayout;
