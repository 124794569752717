import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col } from "antd";
import { Title } from "../../Utils/UI/Text";
import { getLogs, getLog } from "../Application/Logs.business";
import { getLogsSelector } from "../Infrastructure/Logs.reducer";
import LogsBoard from "./LogsBoard";
import { useParams, useNavigate } from "react-router-dom";
import "./Logs.css";

const { Content } = Layout;

export default function Logs() {
  const logs = useSelector(getLogsSelector);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getLogs();
    if (id) {
      getLog(id);
    }
  }, [id]);
  return (
    <Content style={{ padding: "20px" }}>
      <Row style={{ marginBottom: 16 }} justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ margin: 0 }}>
            Logs
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <LogsBoard
            logs={logs}
            getLog={getLog}
            onNavigate={navigate}
            getLogs={getLogs}
          />
        </Col>
      </Row>
    </Content>
  );
}
