import React from "react";
import { useSelector } from "react-redux";
import { getSelectedLogSelector } from "../Infrastructure/Logs.reducer";

const DataLog = () => {
  const selectedLog = useSelector(getSelectedLogSelector);

  if (!selectedLog) return <div>No hay datos disponibles</div>;

  return (
    <div className="logs-data">
      {Object.entries(selectedLog.data).map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong> {String(value)}
        </div>
      ))}
    </div>
  );
};

export default DataLog;
