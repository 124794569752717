class Formatter {
  static round(number, fixed = 2) {
    return !number ? 0 : Number.parseFloat(number).toFixed(fixed);
  }

  static formatNumber(number) {
    if (typeof number !== "number") {
      return "";
    }
    const formattedNumber = number.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formattedNumber;
  }

  static formatUsd(number) {
    if (number === undefined || isNaN(number)) {
      return 0;
    }
    const isInteger = Number.isInteger(number);

    return number.toLocaleString("en-US", {
      style: "decimal",
      minimumFractionDigits: isInteger ? 0 : 2,
      maximumFractionDigits: 2,
    });
  }

  static cleanParamsFromTableToAPI({
    page = 0,
    limit = 10,
    typeValue,
    statusValue,
    stateValue,
    privateValue,
    dates,
    field_sort,
    sort,
  }) {
    const cleanParams = {
      limit,
      page,
      filter: {},
      field_sort,
      sort,
    };

    if (dates.length > 0) {
      const [start_date, finish_date] = dates;
      cleanParams.filter.start_date = start_date;
      cleanParams.filter.finish_date = finish_date;
    }

    if (!!typeValue) cleanParams.filter.type = typeValue;
    if (!!statusValue) cleanParams.filter.status = statusValue;
    if (!!stateValue) cleanParams.filter.state = stateValue;
    if (!!privateValue) cleanParams.filter.private = privateValue;
    if (!!field_sort) cleanParams.field_sort = field_sort;
    if (!!sort) cleanParams.sort = sort;

    return cleanParams;
  }

  static formatNumberNoRounding(number) {
    if (typeof number !== "number") {
      return "";
    }
    const formattedNumber = number.toLocaleString("en-US", {
      maximumFractionDigits: 20,
    });

    return formattedNumber;
  }
}

export default Formatter;
